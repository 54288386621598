import moment from 'moment'

var field = {
  width: '100px',
  display: 'inline-block',
  paddingRight: '6px',
  textAlign: 'left'
}

let DEFAULT_FORM_VALUES = {
  outstandingBalance: 0,
  paymentAmount: 0,
  paymentAmountRaw: 0,
  ownerPaymentMethodID: 0,
  cardType: '',
  paymentMethodType: '',
  lastFourDigits: '',
  creditCardNumber: '',
  cardHolderName: '',
  expirationDate: null,
  cvc: '',
  accountHolderName: '',
  accountType: '',
  bankName: '',
  routingNumber: '',
  accountNumber: '---',
  addBankPaymentMethod: false,
  addCreditCardPaymentMethod: false,
  zipcode: ''
}

export const data = () => ({
  formComplete: false,
  formData: { ...DEFAULT_FORM_VALUES },
  loading: true,
  isDebug: true,
  toggle: false,
  openOnFocus: true,
  requestJwtToken: '',
  convenienceFee: 0,
  paymentMethods: [],
  paymentMethodTypes: [],
  accountTypes: [],
  accountAliasBankAccount: '',
  accountAliasCreditCard: '',
  hoaName: '',
  paymentStreet: '',
  paymentCityStateZip: '',
  accountNumber: '',
  accountNumberLine: '',
  formatDateFn: function(value) {
    return value && value != undefined
      ? moment(value.toString(), 'YYYY/MM/DD HH:mm:ss')
          .format('MM-DD-YYYY')
          .toString()
      : ''
  },

  formatter: new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  }),

  styles: {
    filterButton: {
      float: 'right',
      marginTop: '4px'
    },
    amountField: Object.assign({}, field, {
      width: '125px'
    })
  },

  masks: {
    creditCard: {
      creditCard: true
    },
    numeral: {
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      prefix: '$'
    },
    custom: {
      delimiters: [',', '.'],
      blocks: [3, 3, 2],
      numericOnly: true
    }
  }
})
