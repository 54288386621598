import _get from 'lodash/get'
import { parseDate } from '@/utilities/Date/parse'
import { bankSummary } from '@/services/Payments/HomeOwner/BankSummary/store'
import _orderBy from 'lodash/orderBy'
import { runAccountActivity } from '@/services/Reports/RunReport/AccountActivity/store'
import { base64toBlob, showPdf } from '@/services/blobutility'

export const methods = {
  determineRows: function(list) {
    if (!Array.isArray(list)) {
      return
    }

    var dateFormat = 'LL'

    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    })

    var rows = list.map(entry => {
      const transactionDate = parseDate(_get(entry, 'transactionDate', null), dateFormat)
      const description = _get(entry, 'description', '')
      const transactionType = _get(entry, 'transactionType', '')
      let tempAmount = _get(entry, 'amount', 0)
      let amount = formatter.format(tempAmount)

      if (tempAmount < 0) {
        tempAmount = tempAmount * -1
        amount = formatter.format(tempAmount)

        amount = `(${amount})`
      }
      const runningBalance = formatter.format(_get(entry, 'runningBalance', 0))

      return {
        transactionDate: transactionDate,
        description: description,
        transactionType: transactionType,
        amount: amount,
        runningBalance: runningBalance
      }
    })

    this.rows = rows
  },

  collapseRow() {
    this.expandRowIndex = -1
  },

  expandRow(index) {
    this.expandRowIndex = index
  },

  async downloadAccountActivityHistoryReport() {
    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    const params = {
      ownerID: this.currentOwnerID,
      startDate: '',
      endDate: '',
      format: 'PDF'
    }

    await runAccountActivity
      .dispatch('runAccountActivityHistoryBase64Report', {
        params
      })
      .then(({ returned }) => {
        loadingComponent.close()

        if (returned && returned != undefined) {
          if (this.isDebug == true) console.debug('base64pdf........................' + returned)

          const contentType = 'application/pdf'
          const blob = base64toBlob(returned, contentType)

          //this.refreshRedirect()

          showPdf(blob)
        }
        return
      })
  },

  determinePendingRows: function(list) {
    if (list) {
      var filtered = list.filter(f => f.status == 'Pending')

      if (filtered) {
        var dateFormat = 'LL'

        var formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2
        })

        this.pendingPaymentsMadeList = filtered.map(entry => {
          const ownerElectronicPaymentID = _get(entry, 'ownerElectronicPaymentID', 0)
          const paymentMethodType = _get(entry, 'paymentMethodType', '')
          const lastFourDigits = _get(entry, 'lastFourDigits', '')
          const amount = formatter.format(_get(entry, 'amount', 0))
          const status = _get(entry, 'status', '')
          const createdDate = parseDate(_get(entry, 'createdDate', null), dateFormat)

          return {
            ownerElectronicPaymentID: ownerElectronicPaymentID,
            paymentMethodType: paymentMethodType,
            lastFourDigits: lastFourDigits,
            amount: amount,
            status: status,
            createdDate: createdDate
          }
        })
      }
    }
  },

  async loadPaymentHistory() {
    const params = {
      hoaId: this.currentHoaId,
      ownerID: this.currentOwnerId
    }

    await bankSummary
      .dispatch('getPaymentHistoryList', {
        params
      })
      .then(({ list }) => {
        this.determineRows(list)
      })
  },

  async loadPendingPaymentsMade() {
    const params = {
      ownerID: this.currentOwnerId
    }

    await bankSummary
      .dispatch('getPendingPaymentsMadeList', {
        params
      })
      .then(({ list }) => {
        this.determinePendingRows(_orderBy(list, 'createdDate', 'desc'))
      })
  },

  reload() {
    this.loading = true

    this.$store.dispatch('hoa/loadOwnerAccountTransactions', {
      ownerID: this.currentOwnerId,
      startDate: null,
      endDate: null,
      showWaiverSummaries: true
    })

    this.rows = []
    this.loadPaymentHistory()
    this.loadPendingPaymentsMade()

    this.loading = false
  }
}
