import moment from 'moment'

export const data = () => ({
  isDebug: true,
  loading: false,
  toggle: false,
  accountActivityList: [],
  expandRowIndex: -1,
  pendingPaymentsMadeList: [],
  ownerAccountBalance: null,
  formatDateFn: function(value) {
    return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
  },

  filters: {
    show: true
  },

  region: {},

  rows: [],

  columns: [
    {
      field: 'transactionDate',
      label: 'Date',
      aria: 'Date',
      width: '20%',
      sortable: true,
      searchable: true
    },
    {
      field: 'description',
      label: 'Description',
      aria: 'Description',
      width: '30%',
      sortable: true,
      searchable: true
    },
    {
      field: 'transactionType',
      label: 'Type',
      aria: 'Type',
      width: '20%',
      sortable: true,
      searchable: true
    },
    {
      field: 'amount',
      label: 'Amount',
      aria: 'Amount',
      width: '15%',
      sortable: true,
      searchable: true
    },
    {
      field: 'runningBalance',
      label: 'Balance',
      aria: 'Balance',
      width: '15%',
      sortable: true,
      searchable: true
    }
  ],

  styles: {
    dateSelector: {
      display: 'flex',
      padding: '12px',
      textAlign: 'center',
      verticalAlign: 'middle',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    filterButton: {
      float: 'right',
      marginTop: '4px'
    }
  }
})
