/*
  import { runAccountActivity } from '@/services/Reports/RunReport/AccountActivity/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'

import { notifyProblem } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  async runAccountActivityHistoryBase64Report({}, { params }) {
    console.log('in runAccountActivityHistoryBase64Report...')
    try {
      const result = await kms.get(
        `/AccountsReceivable/Reports/AccountActivityHistory/GetAsBaseSixtyFour?ownerID=${params.ownerID}&startDate=${params.startDate}&endDate=${params.endDate}&format=${params.format}`
      )

      if (isDebug == true)
        console.debug('runAccountActivityHistoryBase64Report log=' + JSON.stringify(result))

      return {
        returned: result
      }
    } catch (exception) {
      notifyProblem(`The account activity history report could not be retrieved.`)
      console.error(exception)
    }

    return {
      returned: ''
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const runAccountActivity = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
