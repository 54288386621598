<template>
  <PageContentLayoutOnly role="region">
    <div
      class="level-left"
      :style="{ display: 'flex', marginBottom: '12px', justifyContent: 'space-between' }"
    >
      <b-button
        tests-id="add-survey-button"
        @click.prevent="createPaymentMethodModal()"
        size="is-small"
        type="is-primary"
        rounded
      >
        Add Payment Method
      </b-button>

      <b-button
        type="is-ghost"
        @click="filters.show = !filters.show"
        :style="{ float: 'right', background: 'none', border: 0 }"
        :class="'filter-button ' + (filters.show ? 'active' : '')"
      >
        <b-icon pack="mdi" icon="filter-outline" size="is-small" style="display: inline-block">
        </b-icon>
        <div style="display: inline-block">Filter</div>
      </b-button>
    </div>

    <ModernTable
      :region="region"
      :filters="filters"
      :rows="rows"
      :columns="columns"
      :pagination="{
        perPage: 12
      }"
      :loading="loading"
      headerColor="#FFF"
      condensed
    >
    </ModernTable>

    <Modal :toggle.sync="toggle">
      <OwnerPaymentMethodModal :ownerPaymentMethod="selectedOwnerPaymentMethod" />
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ModernTable from '@/components/tables/Modern/Table'
import { methods } from './keys/methods'
import { data } from './keys/data'
import OwnerPaymentMethodModal from './component/OwnerPaymentMethodModal'
import Modal from '@/components/Modal'

export default {
  name: 'OwnerPaymentMethod',
  components: {
    PageContentLayoutOnly,
    ModernTable,
    OwnerPaymentMethodModal,
    Modal
  },

  data,

  methods,

  computed: {
    ...mapState({
      unit: state => state.hoa.unit,
      ...mapGetters('user', ['authUser', 'retrieveUser', 'currentHoaId', 'currentOwnerId'])
    })
  },

  watch: {
    toggle() {
      if (this.toggle == false) {
        this.toggle = false

        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }

        this.reRender()
      }
    }
  },

  mounted() {
    this.reload()
  },

  i18n: {
    messages: {
      en: { survey: { title: 'Community Info' } }
    }
  }
}
</script>

<style></style>
