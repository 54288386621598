/*
  import { isNumeric } from '@/utilities/validate/isNumeric';
*/

export const isNumeric = function({ n }) {
  try {
    return !isNaN(parseFloat(n)) && isFinite(n)
  } catch (exception) {
    return false
  }
}
