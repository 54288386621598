/*
  Example:
    import parseCityStateZip from '@/utilities/address/parseCityStateZip'

    const parsedAddress = parseCityStateZip ({
      address: {
        // USA address fields
        city
        state
        postalCode
      }
    })
*/

import _get from 'lodash/get'

export default ({ address }) => {
  if (typeof address !== 'object' || address === null) {
    // console.error('address/parse can only parse addresses that are iterable objects, got', address)
    return ''
  }

  /*
    false -> USA Address
      city, state postalCode

  */
  var isForeign = _get(address, 'foreignAddress', false)

  if (!isForeign) {
    let _address = ''

    const city = _get(address, 'city', null)
    if (typeof city === 'string') {
      _address += ' ' + city + ','
    }

    const state = _get(address, 'state', null)
    if (typeof state === 'string') {
      _address += ' ' + state
    }

    const postalCode = _get(address, 'postalCode', null)
    if (typeof postalCode === 'string') {
      _address += ' ' + postalCode
    }

    const zip = _get(address, 'zip', null)
    if (typeof zip === 'string') {
      _address += ' ' + zip
    }

    return _address
  }
}
