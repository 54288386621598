import moment from 'moment'

export const data = () => ({
  isDebug: true,
  loading: false,
  selectedOwnerPaymentMethod: null,
  ownerPaymentMethodID: null,
  ownerID: null,
  scheduledOwnerPaymentMethodID: null,
  paymentMethods: [],
  formatDateFn: function(value) {
    return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
  }
})
