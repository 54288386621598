/*
  import { arSettingStore } from '@/services/ARSettings/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await reserveStore.dispatch ('getByHoaId', {
      hoaId
    });
  */

  async getByHoaId({}, { hoaID }) {
    try {
      console.debug('in getByHoaId...' + hoaID)

      let path = `/AccountsReceivable/ARSetting/GetByHOAID?hoaID=${hoaID}`

      const result = await kms.get(path)

      if (isDebug == true) console.debug('getByHoaId=' + JSON.stringify(result))

      return {
        result: result
      }
    } catch (exception) {
      console.debug(`There was a problem retrieving the A/R Settings.`)
      console.error(exception)
    }

    return {
      result: {}
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const arSettingStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
