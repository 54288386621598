import moment from 'moment'

export const data = () => ({
  isDebug: true,
  loading: false,
  selectedOwnerPaymentMethod: null,
  toggle: false,
  ownerPaymentMethodTypes: [],
  newID: 0,
  requestJwtToken: '',
  formatDateFn: function(value) {
    return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
  },

  filters: {
    show: false
  },

  region: {},

  rows: [],

  columns: [
    {
      field: 'type',
      label: 'Type',
      aria: 'Type',
      width: '50%',
      sortable: true,
      searchable: true
    },
    {
      field: 'accountAlias',
      label: 'Account Alias',
      aria: 'Account Alias',
      width: '30%',
      sortable: true,
      searchable: true
    },
    {
      field: 'lastFourDigits',
      label: 'Last Four Digits',
      aria: 'Last Four Digits',
      width: '20%',
      sortable: true,
      searchable: true
    }
  ],
  styles: {
    datePickerContainer: { display: 'flex', alignItems: 'end' },
    datePicker: { margin: 0, marginRight: '12px' }
  }
})
