import moment from 'moment'

export const data = () => ({
  loading: true,
  isDebug: false,
  formatDateFn: function(value) {
    return value && value != undefined
      ? moment(value.toString(), 'YYYY/MM/DD HH:mm:ss')
          .format('MM-DD-YYYY')
          .toString()
      : ''
  },
  styles: {
    pageContentSection: {
      width: '100%'
    },
    mainContainer: {
      display: 'flex'
    },
    content: {
      margin: '0 auto',
      width: 'calc(100% - 160px)',
      overflowX: 'auto'
    },
    menu: {
      width: '260px',
      paddingLeft: '20px'
    }
  },
  tabs: [
    {
      label: 'Account Activity',
      name: 'accountActivity'
    },
    {
      label: 'Payment Methods',
      name: 'ownerPaymentMethods'
    },
    {
      label: 'Automatic Payments',
      name: 'scheduledPayments'
    },
    {
      label: 'Statements',
      name: 'statementsOfAccount'
    }
  ],
  transitionName: 'slide-up'
})
