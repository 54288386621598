import _get from 'lodash/get'
import { ownerPaymentMethod } from '@/services/OwnerPaymentMethods/store'
import Button from '@/components/buttons/Button'

export const methods = {
  determineRows: function(list) {
    if (!Array.isArray(list)) {
      return
    }

    var rows = list.map(entry => {
      const id = _get(entry, 'ownerPaymentMethodID', 0)
      const cardType = _get(entry, 'cardType', '')
      const type = _get(entry, 'paymentMethodType', '')
      const lastFourDigits = _get(entry, 'lastFourDigits', '')
      const accountAlias = _get(entry, 'accountAlias', '')

      const identifier =
        !cardType || cardType.length == 0 ? lastFourDigits : `${cardType} - ${lastFourDigits}`

      return {
        type: type,
        accountAlias: accountAlias,
        lastFourDigits: lastFourDigits,
        erase: {
          component: Button,
          props: {
            onClick: () => {
              this.confirmDeletOwnerPaymentMethod(id, identifier)
            },
            text: 'Delete'
          }
        }
      }
    })

    this.rows = rows
  },

  createPaymentMethodModal() {
    this.selectedOwnerPaymentMethod = null
    this.toggle = true
  },

  confirmDeletOwnerPaymentMethod(id, name) {
    this.$buefy.dialog.confirm({
      title: 'Deleting Payment Method',
      message: `Are you sure you want to <b>delete</b> the payment method for '${name}'?`,
      confirmText: 'Delete Payment Method',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteOwnerPaymentMethod(id)
    })
  },

  async deleteOwnerPaymentMethod(id) {
    this.selectedOwnerPaymentMethod = null
    await ownerPaymentMethod.dispatch('deleteOwnerPaymentMethod', {
      ownerPaymentMethodID: id,
      done: () => {
        this.loading = false
        this.reRender()
      }
    })
  },

  async loadOwnerPaymentMethods() {
    const selectedOwnerUser = this.authUser.ownerUsers.filter(
      f => f.ownerID === this.currentOwnerId
    )

    const params = {
      ownerUserID:
        selectedOwnerUser[0] !== undefined && selectedOwnerUser[0]
          ? selectedOwnerUser[0].ownerUserID
          : _get(this, 'authUser.ownerUserID', null)
    }

    if (this.isDebug == true) console.debug('in loadOwnerPaymentMethods()...')
    await ownerPaymentMethod
      .dispatch('getOwnerPaymentMethods', {
        params: params
      })
      .then(({ list }) => {
        this.ownerPaymentMethods = list
        this.determineRows(list)
      })
  },

  reRender() {
    //Necessary for now to force other tabs to re-render
    //this.$forceUpdate()
    //this.$router.go(0)
    this.reload()
  },

  async reload() {
    this.loading = true

    this.rows = []

    await this.loadOwnerPaymentMethods()

    this.loading = false
  }
}
