<template>
  <PageContentLayoutOnly role="region" class="light-grey">
    <div class="pb-4">
      <router-link to="/payOutstandingBalance">
        <button class="button is-primary is-rounded">
          Make a Payment
        </button>
      </router-link>
      <button
        @click.prevent="downloadAccountActivityHistoryReport()"
        class="button is-primary is-rounded ml-3"
      >
        View Account Activity PDF
      </button>
    </div>
    <div
      v-if="pendingPaymentsMadeList && pendingPaymentsMadeList.length > 0"
      class="blue-title pb-1"
    >
      Pending
    </div>
    <div v-if="pendingPaymentsMadeList && pendingPaymentsMadeList.length > 0">
      <div
        class="pb-2"
        v-for="item in pendingPaymentsMadeList"
        :key="item.ownerElectronicPaymentID"
      >
        <div class="container notification is-white">
          <span>{{ `A ${item.paymentMethodType} payment of` }}</span>
          <span class="light-blue-text">{{ ` ${item.amount} ` }}</span>
          <span>{{ `submitted on ${item.createdDate} and is ${item.status} Settlement` }}</span>
        </div>
      </div>
    </div>

    <div class="columns pt-5">
      <div class="column is-5" :style="{ width: '220px' }">
        <b-field label="Balance Owed">
          <h5 class="margin-0" account-activity-balance>{{ ownerAccountBalance | accounting }}</h5>
        </b-field>
      </div>
      <div class="colum  is-5" :style="{ padding: '10px 20px', width: '350px' }">
        <label class="label">Summary</label>
        <ul>
          <li v-for="charge in ownerAccountChargeTypeBalances" :key="charge.chargeTypeId">
            <label class="h7 margin-0 is-inline-block has-width-100">
              {{ charge.chargeTypeName }}</label
            >

            <label class="h7 margin-0 is-inline-block has-width-100 has-text-right">
              {{ charge.amount | accounting }}
            </label>
          </li>
        </ul>
      </div>
    </div>

    <div class="blue-title pt-5 pb-1">History</div>
    <ModernTable
      :region="region"
      :filters="filters"
      :rows="rows"
      :columns="columns"
      :pagination="{
        perPage: 12
      }"
      :loading="loading"
      headerColor="#FFF"
      condensed
    >
      <template v-slot:header>
        <div :style="{ width: '100%', height: '60px' }">
          <b-button
            type="is-ghost"
            @click="filters.show = !filters.show"
            :style="{ float: 'right', background: 'none', border: 0 }"
            :class="'filter-button ' + (filters.show ? 'active' : '')"
          >
            <b-icon pack="mdi" icon="filter-outline" size="is-small" style="display: inline-block">
            </b-icon>
            <div style="display: inline-block">Filter</div>
          </b-button>
        </div>
      </template>
    </ModernTable>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import _get from 'lodash/get'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import { methods } from './keys/methods'
import { data } from './keys/data'
import ModernTable from '@/components/tables/Modern/Table'

export default {
  name: 'AccountActivity',
  components: {
    PageContentLayoutOnly,
    ModernTable
  },

  data,

  methods,

  computed: {
    ...mapState({
      unit: state => state.hoa.unit,
      ...mapGetters('user', ['authUser', 'retrieveUser', 'currentHoaId', 'currentOwnerId'])
    }),
    ...mapState('hoa', ['unit', 'ownerAccountChargeTypeBalances'])
  },

  watch: {
    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  mounted() {
    this.unsubscribe = this.$store.subscribe(mutation => {
      if (mutation.type === 'hoa/save') {
        this.ownerAccountBalance = _get(mutation, ['payload', 'ownerAccountBalance'], null)

        this.loading = false
      }
    })

    this.$store.dispatch('hoa/loadOwnerAccountTransactions', {
      ownerID: this.currentOwnerId,
      startDate: null,
      endDate: null,
      showWaiverSummaries: true
    })

    this.reload()
  },

  beforeDestroy() {
    this.unsubscribe()
  },

  i18n: {
    messages: {
      en: { accountActivity: { title: 'Account Activity' } }
    }
  }
}
</script>

<style lang="scss" scoped>
.downarrow {
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
}
</style>
