<template>
  <PageContentLayoutOnly role="region">
    <div>
      <b-notification type="is-info is-light" has-icon aria-close-label="Close notification">
        Automatic Payments allow you to set up your Homeowners Assessment payments to automatically
        debit from your credit card or bank account when they are due. These charges are typically
        applied around the 7th or 8th of the month that the assessment is applied. To enable
        Automatic Payments, select a Payment Method from the list below. If you do not have any
        Payment Methods listed, please go to the Payment Methods tab and add your payment method
        there, then return to this tab.
      </b-notification>
    </div>

    <div class="pt-5">
      <label class="label"
        >Payment Method<Info
          title="Name"
          information="Select the stored payment that you want to use to schedule this payment."
      /></label>
      <valid-select
        placeholder="Payment Method"
        vid="paymentMethodID"
        v-model="ownerPaymentMethodID"
        aria-required="The payment method is required"
        rules="required"
      >
        <option v-for="option in paymentMethods" :value="option.value" :key="option.value">{{
          option.label
        }}</option>
      </valid-select>
    </div>
    <div class="pt-5" v-if="scheduledOwnerPaymentMethodID">
      <button
        @click.prevent="schedulePayment(false)"
        class="button is-primary is-rounded"
        :disabled="loading"
      >
        Update Scheduled Payment</button
      ><span class="pl-3"></span>
      <button
        @click.prevent="confirmRemoveScheduledPayment"
        class="button is-primary is-rounded"
        :disabled="loading"
      >
        Remove Scheduled Payment
      </button>
    </div>
    <div class="pt-5" v-else>
      <button
        @click.prevent="schedulePayment(false)"
        class="button is-primary is-rounded"
        :disabled="loading"
      >
        Schedule Payment
      </button>
    </div>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import { methods } from './keys/methods'
import { data } from './keys/data'
import ValidSelect from '@/components/inputs/ValidSelect'
import Info from '@/components/icons/Info'

export default {
  name: 'OwnerPaymentMethod',
  components: {
    PageContentLayoutOnly,
    ValidSelect,
    Info
  },

  data,

  methods,

  computed: {
    ...mapState({
      unit: state => state.hoa.unit,
      ...mapGetters('user', ['authUser', 'retrieveUser', 'currentHoaId', 'currentOwnerId'])
    })
  },

  mounted() {
    this.reload()
  },

  i18n: {
    messages: {
      en: { scheduledPayment: { title: 'Automatic Payments' } }
    }
  }
}
</script>

<style></style>
