import _get from 'lodash/get'
import { outstandingPayment } from '@/services/Payments/HomeOwner/OutstandingPayment/store'
import { ownerPaymentMethod } from '@/services/OwnerPaymentMethods/store'
import { getOwner } from '@/services/Roster/Owner/getOwner'
import { notifyError, notifyMessage } from '@/services/notify'

export const methods = {
  async loadPaymentMethods() {
    const selectedOwnerUser = this.authUser.ownerUsers.filter(
      f => f.ownerID === this.currentOwnerId
    )

    console.debug('selectedOwnerUser[===' + JSON.stringify(selectedOwnerUser))

    const params = {
      ownerUserID:
        selectedOwnerUser[0] !== undefined && selectedOwnerUser[0]
          ? selectedOwnerUser[0].ownerUserID
          : _get(this, 'authUser.ownerUserID', null)
    }

    await outstandingPayment
      .dispatch('getPaymentMethodList', {
        params: params
      })
      .then(({ list }) => {
        if (list) {
          this.paymentMethods = list
        }
      })
  },

  async getOwner() {
    const { owner, exception } = await getOwner({
      ownerID: this.currentOwnerId
    })

    if (exception) {
      console.debug(exception)
      return
    }

    this.scheduledOwnerPaymentMethodID = _get(owner, ['scheduledOwnerPaymentMethodID'], null)
    this.ownerPaymentMethodID = _get(owner, ['scheduledOwnerPaymentMethodID'], null)
  },

  async updateSchedulePayment() {
    console.debug('in updateSchedulePayment...')
  },

  async confirmRemoveScheduledPayment() {
    this.$buefy.dialog.confirm({
      title: 'Removing Scheduled Payment',
      message: `Are you sure you want to <b>remove</b> this scheduled payment?`,
      confirmText: 'Remove Scheduled Payment',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: async () => await this.schedulePayment(true)
    })
  },

  async schedulePayment(remove) {
    console.debug('in schedulePayment...')
    if (!this.ownerPaymentMethodID && remove && remove === false) {
      notifyError('Please select a payment method')
      return
    }

    if (!this.currentOwnerId) {
      notifyError('There was a problem retrieving your owner information.')
      return
    }

    this.loading = true
    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    try {
      await ownerPaymentMethod.dispatch('submitScheduledPayment', {
        ownerID: this.currentOwnerId,
        ownerPaymentMethodID: remove && remove === true ? null : this.ownerPaymentMethodID,
        scheduledDayPerMonth: null,
        done: ({ details }) => {
          if (details) {
            if (remove && remove === false) {
              notifyMessage(`Successfully scheduled a payment.`)
            } else if (remove && remove === true) {
              notifyMessage(`Successfully removed your scheduled payment.`)
            }
            this.getOwner()
            this.loading = false
          }
        }
      })
    } catch (e) {
      notifyError('There was a problem scheduling your payment.')
      loadingComponent.close()
    }
    this.loading = false
    loadingComponent.close()
  },

  async reload() {
    this.loading = true

    await this.getOwner()
    await this.loadPaymentMethods()

    this.loading = false
  }
}
