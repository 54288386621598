export function data() {
  return {
    formData: {
      cardType: '',
      paymentMethodType: '',
      lastFourDigits: '',
      creditCardNumber: '',
      cardHolderName: '',
      expirationDate: null,
      accountHolderName: '',
      accountType: '',
      bankName: '',
      routingNumber: '',
      accountNumber: '',
      accountAlias: '',
      zipCode: ''
    },
    paymentMethodTypes: [],
    accountTypes: [],
    isDebug: true,
    isOpen: false,
    loading: true,
    isFetching: false,
    openOnFocus: true,
    buttonText: 'Add',

    masks: {
      creditCard: {
        creditCard: true
      },
      numeral: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        prefix: '$'
      },
      custom: {
        delimiters: [',', '.'],
        blocks: [3, 3, 2],
        numericOnly: true
      }
    }
  }
}
