<template>
  <PageContentLayoutOnly headerColor="#fafafa">
    <div class="whitecolor">
      <div class="level pl-5 pr-5">
        <div class="pt-5 level-left level-item title">{{ $t('payOutstandingBalance.title') }}</div>
        <div class="level-right">
          <div class="level-item">
            <svg
              width="30"
              height="30"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              ></path>
            </svg>
          </div>
          <div class="level-item">
            <b-button label="Back to Dashboard" @click.prevent="returnToParent()" />
          </div>
        </div>
      </div>
      <div class="pl-2 pb-5">
        <button
          @click.prevent="addPaymentMethodRedirect()"
          type="button"
          class="button is-primary is-rounded"
        >
          Add Payment Method
        </button>
      </div>
      <div class="tile is-ancestor">
        <div class="tile is-vertical is-7 pl-2 pr-2 pb-2">
          <div class="tile is-parent">
            <div class="tile  is-child notification headerColor" v-if="formData">
              <div>
                <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                  <transition name="fade" mode="out-in" appear>
                    <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
                      <fieldset class="box is-7">
                        <div class="pb-5">
                          <label class="label"
                            >Outstanding Balance<Info
                              title="Name"
                              information="The outstanding balance of that is owed."
                          /></label>
                          <valid-input
                            style="width:50px !important;"
                            aria-readonly="true"
                            aria-disabled="true"
                            disabled="true"
                            name="outstandingBalance"
                            type="text"
                            vid="OutstandingBalance"
                            placeholder="Outstanding Balance"
                            spellcheck="true"
                            aria-label="Outstanding Balance"
                            rules="required"
                            class="is-small"
                            v-model="formData.outstandingBalance"
                          />
                        </div>
                        <div>
                          <label class="label"
                            >Payment Amount<Info
                              title="Name"
                              information="The amount that you are making the payment for."
                          /></label>
                          <b-input
                            name="PaymentAmount"
                            maxlength="100"
                            type="text"
                            vid="Payment Amount"
                            placeholder="Payment Amount"
                            aria-label="Payment Amount"
                            rules="max:100|required"
                            class="is-small"
                            v-model="formData.paymentAmount"
                            v-cleave="masks.numeral"
                            @input.native="onInput"
                          />
                        </div>
                        <div>
                          <label class="label"
                            >Convenience Fee<Info
                              title="Name"
                              information="The amount added for processing the payment."
                          /></label>
                          <div>
                            {{ `${convenienceFee ? convenienceFee : 0}` | currency }}
                          </div>
                          <label class="label"
                            >Total<Info
                              title="Name"
                              information="The payment plus the convenience fee. This will be the total amount processed."
                          /></label>
                          <div>
                            {{
                              `${
                                convenienceFee
                                  ? Number.parseFloat(convenienceFee) +
                                    Number.parseFloat(formData.paymentAmountRaw)
                                  : 0
                              }` | currency
                            }}
                          </div>
                        </div>
                      </fieldset>
                      <fieldset class="box is-7">
                        <div>
                          <label class="label"
                            >Payment Method Type<Info
                              title="Title"
                              information="The type of the payment method. Select the one-time payment type 'Credit Card' or 'Bank Account'. Select a stored account for a saved payment type. The selected payment type will be used to make the payment."
                          /></label>
                          <valid-select
                            placeholder="Payment Method Type"
                            vid="paymentMethodType"
                            v-model="formData.paymentMethodType"
                            aria-required="The payment method type is required"
                            rules="required"
                          >
                            <option
                              v-for="option in paymentMethodTypes"
                              :value="option.value"
                              :key="option.value"
                              >{{ option.label }}</option
                            >
                          </valid-select>
                        </div>
                        <div
                          v-if="
                            formData.paymentMethodType &&
                              formData.paymentMethodType != undefined &&
                              formData.paymentMethodType == 'stored'
                          "
                          class="pt-5"
                        >
                          <label class="label"
                            >Payment Method<Info
                              title="Name"
                              information="Select the stored payment that you want to use to make this payment."
                          /></label>
                          <valid-select
                            placeholder="Payment Method"
                            vid="paymentMethodID"
                            v-model="formData.ownerPaymentMethodID"
                            aria-required="The payment method is required"
                            rules="required"
                          >
                            <option
                              v-for="option in paymentMethods"
                              :value="option.value"
                              :key="option.value"
                              >{{ option.label }}</option
                            >
                          </valid-select>
                        </div>
                      </fieldset>

                      <fieldset
                        v-if="
                          formData.paymentMethodType &&
                            formData.paymentMethodType != undefined &&
                            formData.paymentMethodType == 'creditcard'
                        "
                        class="box is-7"
                      >
                        <div class="column is-12">
                          <label class="label"
                            >Credit Card Number<Info
                              title="Name"
                              information="The credit card number."
                              tabindex="-1"
                          /></label>
                          <b-input
                            id="cardnumber"
                            v-model="formData.creditCardNumber"
                            name="creditCardNumber"
                            type="text"
                            vid="creditCardNumber"
                            placeholder="Credit Card Number"
                            rules="required|max:100"
                            tabindex="1"
                          >
                          </b-input>
                        </div>
                        <div class="column is-12">
                          <label class="label"
                            >Card Holder Name<Info
                              title="Name"
                              information="The card holder name."
                              tabindex="-1"
                          /></label>
                          <valid-input
                            v-model="formData.cardHolderName"
                            name="cardHolderName"
                            type="text"
                            vid="cardHolderName"
                            placeholder="Card Holder Name"
                            rules="required|max:255"
                            tabindex="2"
                          />
                        </div>
                        <div class="column is-12 pt-2">
                          <div>
                            <label class="label"
                              >Expiration Date<Info
                                title="Expiration Date"
                                information="The date that the credit card expires."
                                tabindex="-1"
                            /></label>
                            <b-field>
                              <DateMonthPicker
                                tabindex="3"
                                required
                                ref="cardExpirationDateComponent"
                              />
                            </b-field>
                          </div>
                          <div class="column is-12 pt-2">
                            <label class="label"
                              >CVC<Info
                                title="CVC"
                                tabindex="-1"
                                information="The credit card verification code. This code is the final three digits of the number printed on the signature strip on the reverse side of your card."
                            /></label>
                          </div>
                          <valid-input
                            v-model="formData.cvc"
                            name="cvc"
                            type="text"
                            vid="cvc"
                            tabindex="4"
                            placeholder="Card Verification Code"
                            rules="required|max:5"
                          />
                          <div class="column is-12 pt-2">
                            <label class="label"
                              >Postal Code<Info
                                tabindex="-1"
                                title="Postal Code"
                                information="The postal code of the credit card holder's mailing address."
                            /></label>
                          </div>
                          <valid-input
                            v-model="formData.zipCode"
                            name="zipCode"
                            type="text"
                            vid="zipCode"
                            placeholder="Zip Code"
                            maxlength="5"
                            rules="required|max:5"
                            tabindex="5"
                          />
                        </div>
                        <div
                          v-if="formData.addCreditCardPaymentMethod === true"
                          class="column is-12"
                        >
                          <label class="label"
                            >Account Alias<Info
                              tabindex="-1"
                              title="Name"
                              information="Assign a name to your account. This can be any name that you want to assign."
                          /></label>
                          <valid-input
                            v-model="formData.accountAliasCreditCard"
                            name="accountAliasCreditCard"
                            type="text"
                            vid="accountAliasCreditCard"
                            placeholder="Account Alias"
                            rules="max:255"
                            tabindex="6"
                          />
                        </div>
                        <div class="column is-12 pl-5">
                          <b-checkbox
                            class="has-left-text"
                            tabindex="7"
                            v-model="formData.addCreditCardPaymentMethod"
                            >Save this Payment Method?</b-checkbox
                          >
                        </div>
                      </fieldset>

                      <fieldset
                        v-if="
                          formData.paymentMethodType &&
                            formData.paymentMethodType != undefined &&
                            formData.paymentMethodType == 'bankaccount'
                        "
                        class="box is-7"
                      >
                        <div class="column is-12" style="padding:10px; white-space:nowrap;">
                          <label class="label"
                            >Account Type<Info
                              title="Title"
                              information="The type of the bank account."
                          /></label>
                          <valid-select
                            placeholder="Account Type"
                            vid="accountType"
                            v-model="formData.accountType"
                            aria-required="The account type is required"
                            rules="required"
                          >
                            <option
                              v-for="option in accountTypes"
                              :value="option.value"
                              :key="option.value"
                              >{{ option.label }}</option
                            >
                          </valid-select>
                        </div>
                        <div class="column is-12">
                          <label class="label"
                            >Account Holder Name<Info
                              title="Name"
                              information="The account holder name."
                          /></label>
                          <valid-input
                            v-model="formData.accountHolderName"
                            name="accountHolderName"
                            type="text"
                            vid="accountHolderName"
                            placeholder="Account Holder Name"
                            rules="required|max:50"
                          />
                        </div>
                        <div class="column is-12">
                          <label class="label"
                            >Routing Number<Info
                              title="Routing Number"
                              information="The routing number of the Bank Account."
                          /></label>
                          <valid-input
                            v-model="formData.routingNumber"
                            name="routingNumber"
                            type="text"
                            vid="routingNumber"
                            placeholder="Routing Number"
                            rules="required|max:255"
                          />
                        </div>
                        <div class="column is-12">
                          <label class="label"
                            >Account Number<Info
                              title="Account Number"
                              information="The account number of the Bank Account."
                          /></label>
                          <valid-input
                            v-model="formData.accountNumber"
                            name="accountNumber"
                            type="text"
                            vid="accountNumber"
                            placeholder="Account Number"
                            rules="required|max:255"
                          />
                        </div>
                        <div v-if="formData.addBankPaymentMethod === true" class="column is-12">
                          <label class="label"
                            >Account Alias<Info
                              title="Name"
                              information="Assign a name to your account. This can be any name that you want to assign."
                          /></label>
                          <valid-input
                            v-model="formData.accountAliasBankAccount"
                            name="accountAliasBankAccount"
                            type="text"
                            vid="accountAliasBankAccount"
                            placeholder="Account Alias"
                            rules="max:255"
                          />
                        </div>
                        <div class="column is-12 pl-5">
                          <b-checkbox class="has-left-text" v-model="formData.addBankPaymentMethod"
                            >Save this Payment Method?</b-checkbox
                          >
                        </div>
                      </fieldset>
                      <fieldset class="box is-7">
                        <div class="pt-5">
                          <label class="label"
                            >Mailing in Your Payment<Info
                              title="Mailing in Your Payment"
                              information="You may mail your payment at no additional cost."
                          /></label>
                          <div>You may mail your payment at no additional cost to:</div>
                          <div>{{ hoaName }}</div>
                          <div>{{ paymentStreet }}</div>
                          <div>{{ paymentCityStateZip }}</div>
                          <div>&nbsp;</div>
                          <div v-html="accountNumberLine"></div>
                        </div>
                      </fieldset>
                      <div>Standard fees will apply for credit and bank transactions</div>
                      <div class="pt-5">
                        <button
                          type="submit"
                          class="button is-primary is-rounded"
                          :disabled="loading"
                        >
                          Send Payment
                        </button>
                      </div>

                      <fieldset>
                        <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                          <div class="field">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </fieldset>
                    </form>
                  </transition>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </PageContentLayoutOnly>
</template>

<script>
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import _get from 'lodash/get'
import Cleave from 'cleave.js'

// components
import Info from '@/components/icons/Info'
import DateMonthPicker from '@/components/inputs/DateMonthYearPicker'
import ValidInput from '@/components/inputs/ValidInput'
import ValidSelect from '@/components/inputs/ValidSelect'
import parseStreet from '@/utilities/address/parseStreet'
import parseCityStateZip from '@/utilities/address/parseCityStateZip'

const cleave = {
  name: 'cleave',
  bind(el, binding) {
    const input = el.querySelector('input')
    input._vCleave = new Cleave(input, binding.value)
  },
  unbind(el) {
    const input = el.querySelector('input')
    input._vCleave.destroy()
  }
}

export default {
  directives: { cleave },
  name: 'PayOutstandingBalance',
  components: {
    ValidInput,
    ValidSelect,
    ValidationObserver,
    ValidationProvider,
    PageContentLayoutOnly,
    Info,
    DateMonthPicker
  },

  data,

  computed: {
    ...mapGetters('user', ['authUser', 'currentHoaId', 'currentOwnerId']),
    ...mapState({})
  },

  mounted() {
    this.loading = true

    if (this.authUser.ownerID) {
      this.reload()
    }

    if (this.authUser.associations !== undefined && this.authUser.associations) {
      const selectedAssociation = this.authUser.associations.filter(
        f => f.hoaID == this.currentHoaId
      )

      if (selectedAssociation[0] !== undefined && selectedAssociation[0]) {
        this.hoaName = selectedAssociation[0].name
        this.paymentStreet = parseStreet({
          address: _get(selectedAssociation[0], 'paymentAddress', null)
        })
        this.paymentCityStateZip = parseCityStateZip({
          address: _get(selectedAssociation[0], 'paymentAddress', null)
        })
        this.loadAccountNumberFromArSetting({ hoaId: this.currentHoaId })
      }
    }

    this.loading = false
  },

  watch: {
    currentHoaId() {
      this.reload()
    },
    'formData.paymentMethodType'() {
      this.loadServiceFees()
    },
    'formData.ownerPaymentMethodID'() {
      this.loadServiceFees()
    }
  },

  methods,

  i18n: {
    messages: {
      en: {
        payOutstandingBalance: { title: 'Payment for Outstanding Balance' }
      }
    }
  }
}
</script>
