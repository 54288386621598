import _get from 'lodash/get'
import { parseDate } from '@/utilities/Date/parse'
import { ownerStatementsStore } from '@/services/OwnerStatements/store'
import moment from 'moment'
import { base64toBlob, showPdf } from '@/services/blobutility'
import Download from '../component/Download'
import { notifyMessage, notifyWarning, notifyProblem } from '@/services/notify'

export const methods = {
  async downloadPdf(ownerStatementID) {
    if (this.isDebug == true) console.debug('downloadPdf...')

    if (!ownerStatementID || ownerStatementID == undefined) {
      notifyProblem('The statements of this account are invalid for download.')
    }

    try {
      const params = {
        ownerStatementID: ownerStatementID,
        asBase64: true,
        asRawBase64: true
      }

      await ownerStatementsStore.dispatch('getDocumentFile', {
        params,
        done: ({ details }) => {
          if (details) {
            if (this.isDebug == true) console.debug('base64pdf........................' + details)

            const contentType = 'application/pdf'
            const blob = base64toBlob(details, contentType)
            showPdf(blob)
            notifyMessage(`Successfully downloaded the statements of account.`)
            return
          }
        }
      })
    } catch (e) {
      if (this.isDebug == true) console.debug(e)
      notifyWarning('There was a problem downloading the statements of account.')
    }
  },

  determineRows: function(list) {
    if (list) {
      var dateFormat = 'LL'

      this.rows = list.map(entry => {
        const billingPeriod = _get(entry, 'billingPeriod', '')
        const billingPeriodParsed = billingPeriod
          ? moment(billingPeriod).format('MMMM') + ' ' + moment(billingPeriod).format('YYYY')
          : ''

        const statementType = _get(entry, 'statementType', '')
        const deliveryType = _get(entry, 'deliveryType', '')
        const recipientName = _get(entry, 'mailingDeliveryItem.recipientName', '')
        const recipientEmailAddresses = _get(
          entry,
          'mailingDeliveryItem.recipientEmailAddresses',
          ''
        )
        const recipientJsonString = _get(entry, 'mailingDeliveryItem.recipientAddressJSON', '')
        let recipientAddress = ''

        if (recipientJsonString) {
          const address = JSON.parse(recipientJsonString)
          console.debug('address=' + JSON.stringify(address))

          if (address) {
            recipientAddress = `${address.AddressLine1 || ''} ${address.AddressLine2 ||
              ''}, ${address.City || ''}, ${address.State || ''}  ${address.Zip || ''}`
          }
        }
        const mailingDate = parseDate(_get(entry, 'createdDate', null), dateFormat)

        return {
          billingPeriod: billingPeriodParsed,
          statementType: statementType
            ? statementType[0].toUpperCase() + statementType.slice(1)
            : '',
          deliveryType: deliveryType ? deliveryType[0].toUpperCase() + deliveryType.slice(1) : '',
          recipientName,
          addressOrEmail: recipientAddress ? recipientAddress : recipientEmailAddresses,
          mailingDate,
          document: {
            component: Download,
            props: {
              documentID: _get(entry, ['documentID'], 0),
              ownerStatementID: _get(entry, 'ownerStatementID', 0),
              downloadPdf: ownerStatementID => {
                this.downloadPdf(ownerStatementID)
              }
            }
          }
        }
      })
    }
  },

  async loadOwnerStatements() {
    const params = {
      ownerID: this.currentOwnerId
    }

    console.debug('authUser ownerID=' + params)

    await ownerStatementsStore
      .dispatch('getOwnerStatementList', {
        ownerID: this.currentOwnerId
      })
      .then(({ list }) => {
        this.determineRows(list)
      })
  },

  reload() {
    this.loading = true

    this.rows = []
    this.loadOwnerStatements()

    this.loading = false
  }
}
