/*
	import { ownerPaymentMethod } from '@/services/OwnerPaymentMethods/store';
*/

import Vue from 'vue'
import Vuex from 'vuex'
import _get from 'lodash/get'
import kms from '@/services/kms'
import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await ownerPaymentMethod.dispatch ('getOwnerPaymentMethods', {
      hoaId
    });
  */
  async getOwnerPaymentMethods({}, { params }) {
    if (isDebug == true) console.debug('getOwnerPaymentMethods=' + JSON.stringify(params))
    try {
      const result = await kms.get(`/Roster/OwnerPaymentMethod/List`, {
        params
      })

      let list = _get(result, 'results', [])
      if (!Array.isArray(list)) {
        list = []
      }

      return {
        list: list
      }
    } catch (exception) {
      notifyProblem(`The owner payment method list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async deleteOwnerPaymentMethod({ dispatch }, { ownerPaymentMethodID, done }) {
    try {
      const path = `/Roster/OwnerPaymentMethod/${ownerPaymentMethodID}?forceDelete=false`

      const results = await kms.delete(path)

      if (isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted this payment method.`)
        done()
      } else {
        notifyError('There was a problem deleting this payment method.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem deleting this payment method.`)
      console.error(exception)
    }
  },

  //Payment Method Type List
  async getPaymentMethodTypeList({}) {
    try {
      let paymentMethods = [
        { value: 'Credit Card', label: 'Credit Card' },
        { value: 'Bank Account', label: 'Bank Account' }
      ]

      if (isDebug == true) console.debug('paymentMethods=' + JSON.stringify(paymentMethods))

      return {
        list: paymentMethods
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the payment method type list.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  //Bank Account Type List
  async getBankAccountTypeList({}) {
    try {
      let accountTypes = [
        { value: 'personal_checking', label: 'Personal Checking' },
        { value: 'business_checking', label: 'Business Checking' },
        { value: 'personal_savings', label: 'Personal Savings' },
        { value: 'business_savings', label: 'Business Savings' }
      ]

      if (isDebug == true) console.debug('accountTypes=' + JSON.stringify(accountTypes))

      return {
        list: accountTypes
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the bank account type list.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  // Add OwnerPaymentMethod
  async addOwnerPaymentMethod(
    { dispatch },
    {
      ownerUserID,
      paymentMethodType,
      token,
      cardType,
      lastFourDigits,
      bankName,
      accountAlias,
      done
    }
  ) {
    if (isDebug == true) console.debug('...in addOwnerPaymentMethod')
    try {
      const results = await kms.post(`/Roster/OwnerPaymentMethod`, {
        ownerUserID,
        paymentMethodType,
        token,
        cardType,
        lastFourDigits,
        bankName,
        accountAlias
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.ownerPaymentMethodID > 0) {
        notifyMessage(`Successfully added the payment method.`)
        done({ details: results })
      } else {
        notifyError('There was a problem adding this payment method.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem adding this payment method.`)
      console.error(exception)
    }
  },

  async submitScheduledPayment(
    { dispatch },
    { ownerID, ownerPaymentMethodID, scheduledDayPerMonth, done }
  ) {
    if (isDebug == true) console.debug('...in submitScheduledPayment')
    try {
      const results = await kms.post(`/Roster/Owner/SetScheduledPaymentWithWallet`, {
        ownerID,
        ownerPaymentMethodID,
        scheduledDayPerMonth
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.ownerID > 0) {
        console.debug(`Successfully successfully scheduled a payment.`)
        done({ details: results })
      } else {
        notifyError('There was a problem adding this scheduled payment.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem adding this scheduled payment.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const ownerPaymentMethod = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
