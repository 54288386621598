import $ from 'jquery'
import { notifyError } from '@/services/notify'
import { ownerPaymentMethod } from '@/services/OwnerPaymentMethods/store'
import { outstandingPayment } from '@/services/Payments/HomeOwner/OutstandingPayment/store'
import { epicGateway } from '@/services/EpicGateway/store'
import luhn from 'luhn'
import { isNumeric } from '@/utilities/validate/isNumeric'

export const methods = {
  async reload() {
    this.loading = true

    await this.loadAccountTypes()

    await this.loadPaymentMethodTypes()

    this.buttonText = 'Add'
    this.loading = false
  },

  open() {
    this.formData = {}

    this.loading = false
  },

  async loadPaymentMethodTypes() {
    await ownerPaymentMethod.dispatch('getPaymentMethodTypeList').then(t => {
      if (t && t.list && t.list != undefined) {
        console.log('list=' + JSON.stringify(t.list))
        this.paymentMethodTypes = t.list
      }
    })
  },

  async loadAccountTypes() {
    await ownerPaymentMethod.dispatch('getBankAccountTypeList').then(t => {
      if (t && t.list && t.list != undefined) {
        console.log('list=' + JSON.stringify(t.list))
        this.accountTypes = t.list
      }
    })
  },

  async submitPaymentMethod() {
    if (this.formData && this.formData != undefined) {
      await this.addPaymentMethod()
    }
  },

  async getFortisJwtOneTimeToken() {
    await outstandingPayment
      .dispatch('getOneTimeFortisJwtToken', {
        hoaID: this.hoaId
      })
      .then(({ result }) => {
        if (result) {
          if (this.isDebug === true)
            console.debug('getOneTimeFortisJwtToken' + JSON.stringify(result))

          if (result) {
            this.requestJwtToken = result
          }
        }
      })
  },

  async addPaymentMethod() {
    if (!this.formData || this.formData == undefined) {
      notifyError('There was a problem processing this payment method.')
      return
    }

    if (
      this.$refs.cardExpirationDateComponent &&
      this.$refs.cardExpirationDateComponent != undefined
    ) {
      this.formData.expirationDate = this.$refs.cardExpirationDateComponent.getValue()

      if (!this.formData.expirationDate || this.formData.expirationDate == undefined) {
        notifyError('The expiration date is required.')
        return
      }
    }

    let lastFourDigits = ''

    await this.getFortisJwtOneTimeToken()

    if (this.formData.paymentMethodType && this.formData.paymentMethodType == 'Credit Card') {
      if (!this.formData.creditCardNumber) {
        notifyError('Please enter a credit card number.')
        return
      }

      //Validate credit card number with luhn
      var isValid = luhn.validate(this.formData.creditCardNumber)

      if (isValid == false) {
        notifyError('Please enter a valid credit card number.')
        return
      }

      lastFourDigits = this.formData.creditCardNumber
        .toString()
        .slice(
          this.formData.creditCardNumber.toString().length - 4,
          this.formData.creditCardNumber.toString().length
        )

      this.loading = true

      if (this.isDebug == true) console.debug('formData=' + JSON.stringify(this.formData))

      try {
        const registerParams = {
          account_number: this.formData.creditCardNumber || '',
          method: 'credit_card'
        }

        await outstandingPayment
          .dispatch('registerOneTimeToken', {
            params: registerParams,
            jwt: this.requestJwtToken,
            amount: this.formData.paymentAmount || 0,
            name: this.formData.cardHolderName || '',
            expMonth: this.formData.expirationDate.month || '',
            expYear: this.formData.expirationDate.year || ''
          })
          .then(async ({ result }) => {
            if (this.isDebug === true)
              console.debug('registerOneTimeToken cc=' + JSON.stringify(result))
            if (result && result.status !== undefined && result.status) {
              notifyError(result.status)
              return
            } else if (result) {
              const selectedOwnerUser = this.authUser.ownerUsers.filter(
                f => f.ownerID === this.ownerId
              )

              await epicGateway.dispatch('addOwnerPaymentMethodCreditCard', {
                cardHolderName: this.formData.cardHolderName || '',
                expMonth: this.formData.expirationDate.month || '',
                expYear: this.formData.expirationDate.year || '',
                ownerUserID:
                  selectedOwnerUser[0] !== undefined && selectedOwnerUser[0]
                    ? selectedOwnerUser[0].ownerUserID
                    : this.authUser.ownerUserID,
                paymentMethodType: this.formData.paymentMethodType || '',
                lastFourDigits: lastFourDigits,
                token: result.token_number || '',
                tokenType: result.token_type || '',
                accountAlias: this.formData.accountAlias || null,
                postalCode: this.formData.zipCode || null,
                done: async ({ details }) => {
                  //auto-close modal
                  if (details) {
                    if (this.isDebug == true) console.debug('details=' + JSON.stringify(details))
                  }

                  this.$emit('update:toggle', (this.toggle = false))
                  $('#clickclose').click()
                  this.loading = false
                }
              })
            }
          })
      } catch (e) {
        notifyError('There was a problem adding your payment method for a credit card.' + e)
      }

      this.loading = false
    }

    if (this.formData.paymentMethodType && this.formData.paymentMethodType == 'Bank Account') {
      if (this.formData.accountNumber.length < 5 || this.formData.accountNumber.length > 17) {
        notifyError('Please enter five to seventeen numbers for the account number.')
        return
      }

      if (isNumeric({ n: this.formData.accountNumber }) == false) {
        notifyError('Please enter only numbers for the account number.')
        return
      }

      if (this.formData.routingNumber.length != 9) {
        notifyError('Please enter 9 numbers for the routing number.')
        return
      }

      if (isNumeric({ n: this.formData.routingNumber }) == false) {
        notifyError('Please enter only numbers for the routing number.')
        return
      }

      lastFourDigits = this.formData.accountNumber
        .toString()
        .slice(
          this.formData.accountNumber.toString().length - 4,
          this.formData.accountNumber.toString().length
        )

      try {
        const registerParams = {
          account_number: this.formData.accountNumber || '',
          routing_number: this.formData.routingNumber || '',
          bank_account_type: this.formData.accountType || '',
          method: 'echeck'
        }

        await outstandingPayment
          .dispatch('registerOneTimeToken', {
            params: registerParams,
            jwt: this.requestJwtToken,
            amount: this.formData.paymentAmount || 0,
            name: this.formData.cardHolderName || ''
          })
          .then(async ({ result }) => {
            if (result && result.status !== undefined && result.status) {
              notifyError(result.status)
              return
            } else if (result) {
              const selectedOwnerUser = this.authUser.ownerUsers.filter(
                f => f.ownerID === this.ownerId
              )

              await epicGateway.dispatch('addOwnerPaymentMethodBankAccount', {
                accountType: this.formData.accountType || '',
                accountHolderName: this.formData.accountHolderName || '',
                ownerUserID:
                  selectedOwnerUser[0] !== undefined && selectedOwnerUser[0]
                    ? selectedOwnerUser[0].ownerUserID
                    : this.authUser.ownerUserID,
                paymentMethodType: this.formData.paymentMethodType || '',
                lastFourDigits: lastFourDigits,
                token: result.token_number || '',
                tokenType: result.token_type || '',
                accountAlias: this.formData.accountAlias || null,
                done: async ({ details }) => {
                  //auto-close modal
                  if (details) {
                    if (this.isDebug == true) console.debug('details=' + JSON.stringify(details))
                  }

                  this.$emit('update:toggle', (this.toggle = false))
                  $('#clickclose').click()
                  this.loading = false
                }
              })
            }
          })
      } catch (e) {
        notifyError('There was a problem adding your payment method for a bank account.' + e)
      }
    }
  }
}
