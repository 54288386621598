import moment from 'moment'

export const data = () => ({
  isDebug: true,
  loading: false,
  toggle: false,
  formatDateFn: function(value) {
    return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
  },

  filters: {
    show: true
  },

  region: {},

  rows: [],

  columns: [
    {
      field: 'billingPeriod',
      label: 'Billing Period',
      aria: 'Billing Period',
      width: '15%',
      sortable: true,
      searchable: true
    },
    {
      field: 'statementType',
      label: 'Statement Type',
      aria: 'Statement Type',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {
      field: 'deliveryType',
      label: 'Delivery Type',
      aria: 'Delivery Type',
      width: '15%',
      sortable: true,
      searchable: true
    },
    {
      field: 'recipientName',
      label: 'Recipient',
      aria: 'Recipient',
      width: '20%',
      sortable: true,
      searchable: true
    },
    {
      field: 'addressOrEmail',
      label: 'Address/Email',
      aria: 'Address/Email',
      width: '30%',
      sortable: true,
      searchable: true
    },
    {
      field: 'mailingDate',
      label: 'Mailing Date',
      aria: 'Mailing Date',
      width: '10%',
      sortable: true,
      searchable: true
    }
  ],

  styles: {
    dateSelector: {
      display: 'flex',
      padding: '12px',
      textAlign: 'center',
      verticalAlign: 'middle',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    filterButton: {
      float: 'right',
      marginTop: '4px'
    }
  }
})
