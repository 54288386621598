<template>
  <div payment-method-modal>
    <div class="is-size-4" v-if="formData">{{ buttonText }} Payment Method</div>
    <ValidationObserver ref="formGroup" v-slot="{ handleSubmit }">
      <transition name="fade" mode="out-in" appear>
        <form @submit.prevent="handleSubmit(submitPaymentMethod)">
          <fieldset>
            <div class="form-grid">
              <div class="column is-12">
                <label class="label"
                  >Account Alias<Info
                    title="Name"
                    information="Assign a name to your account. This can be any name that you want to assign."
                /></label>
                <valid-input
                  v-model="formData.accountAlias"
                  name="accountAlias"
                  type="text"
                  vid="accountAlias"
                  placeholder="Account Alias"
                  rules="max:255"
                />
              </div>
              <div class="column is-12" v-if="formData.paymentMethodType == 'Credit Card'">
                <div class="column is-12">
                  <label class="label"
                    >Credit Card Number<Info title="Name" information="The credit card number."
                  /></label>
                  <b-input
                    v-model="formData.creditCardNumber"
                    name="creditCardNumber"
                    type="text"
                    vid="creditCardNumber"
                    placeholder="Credit Card Number"
                    rules="required|max:100"
                  />
                </div>
                <div class="column is-12">
                  <label class="label"
                    >Card Holder Name<Info title="Name" information="The card holder name."
                  /></label>
                  <valid-input
                    v-model="formData.cardHolderName"
                    name="cardHolderName"
                    type="text"
                    vid="cardHolderName"
                    placeholder="Card Holder Name"
                    rules="required|max:255"
                  />
                </div>
                <div class="column is-12 pt-2">
                  <div>
                    <label class="label"
                      >Expiration Date<Info
                        title="Expiration Date"
                        information="The date that the credit card expires."
                    /></label>
                    <b-field>
                      <DateMonthPicker required ref="cardExpirationDateComponent" />
                    </b-field>
                  </div>
                </div>
                <div class="column is-12">
                  <label class="label"
                    >Postal Code<Info
                      title="Postal Code"
                      information="The postal code of the credit card holder's mailing address."
                  /></label>
                  <valid-input
                    v-model="formData.zipCode"
                    name="zipCode"
                    type="text"
                    vid="zipCode"
                    maxlength="5"
                    placeholder="Zip Code"
                    rules="required|max:5"
                  />
                </div>
              </div>
              <div class="column is-12" v-if="formData.paymentMethodType == 'Bank Account'">
                <div class="column is-12">
                  <label class="label"
                    >Account Holder Name<Info title="Name" information="The account holder name."
                  /></label>
                  <valid-input
                    v-model="formData.accountHolderName"
                    name="accountHolderName"
                    type="text"
                    vid="accountHolderName"
                    placeholder="Account Holder Name"
                    rules="required|max:50"
                  />
                </div>
                <div class="column is-12">
                  <label class="label"
                    >Routing Number<Info
                      title="Routing Number"
                      information="The routing number of the Bank Account."
                  /></label>
                  <valid-input
                    v-model="formData.routingNumber"
                    name="routingNumber"
                    type="text"
                    vid="routingNumber"
                    placeholder="Routing Number"
                    rules="required|max:255"
                  />
                </div>
                <div class="column is-12">
                  <label class="label"
                    >Account Number<Info
                      title="Account Number"
                      information="The account number of the Bank Account."
                  /></label>
                  <valid-input
                    v-model="formData.accountNumber"
                    name="accountNumber"
                    type="text"
                    vid="accountNumber"
                    placeholder="Account Number"
                    rules="required|max:255"
                  />
                </div>
                <div class="column is-12" style="padding:10px; white-space:nowrap;">
                  <label class="label"
                    >Account Type<Info title="Title" information="The type of the bank account."
                  /></label>
                  <valid-select
                    placeholder="Account Type"
                    vid="accountType"
                    v-model="formData.accountType"
                    aria-required="The account type is required"
                    rules="required"
                  >
                    <option
                      v-for="option in accountTypes"
                      :value="option.value"
                      :key="option.value"
                      >{{ option.label }}</option
                    >
                  </valid-select>
                </div>
              </div>
              <div class="column is-12" style="padding:10px; white-space:nowrap;">
                <label class="label"
                  >Payment Method Type<Info
                    title="Title"
                    information="The type of the payment method. Select 'Credit Card' or 'Bank Account'."
                /></label>
                <valid-select
                  placeholder="Payment Method Type"
                  vid="paymentMethodType"
                  v-model="formData.paymentMethodType"
                  aria-required="The payment method type is required"
                  rules="required"
                >
                  <option
                    v-for="option in paymentMethodTypes"
                    :value="option.value"
                    :key="option.value"
                    >{{ option.label }}</option
                  >
                </valid-select>
              </div>
              <div class="pt-5">
                <button type="submit" :disabled="loading" class="button is-primary is-rounded">
                  {{ buttonText }}
                </button>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <ValidationProvider vid="providerErrors" v-slot="{ errors }">
              <div class="field">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </fieldset>
        </form>
      </transition>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import Info from '@/components/icons/Info'
import ValidInput from '@/components/inputs/ValidInput'
import DateMonthPicker from '@/components/inputs/DateMonthYearPicker'
import ValidSelect from '@/components/inputs/ValidSelect'
import Cleave from 'cleave.js'

const cleave = {
  name: 'cleave',
  bind(el, binding) {
    const input = el.querySelector('input')
    input._vCleave = new Cleave(input, binding.value)
  },
  unbind(el) {
    const input = el.querySelector('input')
    input._vCleave.destroy()
  }
}

export default {
  directives: { cleave },
  props: {
    ownerPaymentMethod: Object
  },

  components: {
    ValidInput,
    ValidSelect,
    ValidationObserver,
    ValidationProvider,
    DateMonthPicker,
    Info
  },

  mounted() {
    this.reload()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ownerId: state => state.user.homeownerSelectedUnit.ownerId,
      ...mapGetters('user', ['authUser'])
    })
  },

  data,
  methods
}
</script>
