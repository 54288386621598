import { outstandingPayment } from '@/services/Payments/HomeOwner/OutstandingPayment/store'
import { ownerPaymentMethod } from '@/services/OwnerPaymentMethods/store'
import { epicGateway } from '@/services/EpicGateway/store'
import { arSettingStore } from '@/services/ARSettings/store'
import { ValidationError } from '@/services/errors'
import { mapActions } from 'vuex'
import { notifyError } from '@/services/notify'
import luhn from 'luhn'
import { isNumeric } from '@/utilities/validate/isNumeric'
import moment from 'moment'

export const methods = {
  ...mapActions('user', ['selectHoaId']),

  async reload() {
    this.loading = true

    if (this.$refs.form) {
      this.$refs.form.reset()
    }

    await this.loadOutstandingBalance()

    await this.loadPaymentMethods()

    await this.loadPaymentMethodTypes()

    await this.loadAccountTypes()

    this.loading = false
  },

  async onInput(event) {
    this.formData.paymentAmountRaw = event.target._vCleave.getRawValue().replace('$', '') //getFormattedValue()
    console.debug('onInput=' + this.formData.paymentAmountRaw)

    await this.loadServiceFees()
  },

  addPaymentMethodRedirect() {
    this.$router.push({
      path: '/billingtabs/ownerPaymentMethods#tab'
    })
  },

  returnToParent() {
    this.$router.push({
      path: '/'
    })
  },

  async loadPaymentMethodTypes() {
    await epicGateway.dispatch('getMakePaymentTypeList').then(t => {
      if (t && t.list && t.list != undefined) {
        console.log('list=' + JSON.stringify(t.list))
        this.paymentMethodTypes = t.list
      }
    })
  },

  async loadAccountNumberFromArSetting() {
    const params = {
      hoaID: this.currentHoaId
    }

    await arSettingStore.dispatch('getByHoaId', params).then(({ result }) => {
      if (result != undefined && result) {
        this.accountNumber = `${result.lockboxID}-${this.currentOwnerId}`
        this.accountNumberLine = `Please be sure to include your Account Number, <b>${this.accountNumber}</b>, on your check and make your check payable to, '${this.hoaName}'.`
      }
    })
  },

  async loadServiceFees() {
    const params = {
      hoaID: this.currentHoaId
    }

    await outstandingPayment
      .dispatch('getServiceFees', {
        params: params
      })
      .then(({ result }) => {
        if (result) {
          const percentage = Number.parseFloat(result.creditCardPercentageServiceFee) * 0.01
          console.debug('percentage=' + percentage)
          const creditCardPercentage = percentage * this.formData.paymentAmountRaw || 0
          console.debug('creditCardPercentage=' + creditCardPercentage)
          const achFlatFeeService = result.achFlatServiceFee

          if (this.formData.paymentMethodType === 'creditcard') {
            this.convenienceFee = creditCardPercentage
            console.debug('creditCardPercentage...' + creditCardPercentage)
          } else if (this.formData.paymentMethodType === 'bankaccount') {
            this.convenienceFee = achFlatFeeService
            console.debug('achFlatFeeService...' + achFlatFeeService)
          } else if (this.formData.paymentMethodType === 'stored') {
            console.debug('stored...' + this.formData.ownerPaymentMethodID)
            if (this.formData.ownerPaymentMethodID && this.formData.ownerPaymentMethodID > 0) {
              console.debug('stored...')
              let feeCreditCard = this.paymentMethods.filter(
                f =>
                  f.label.indexOf('Credit Card') > -1 &&
                  f.value === this.formData.ownerPaymentMethodID
              )
              if (feeCreditCard[0] !== undefined && feeCreditCard[0]) {
                if (feeCreditCard[0].value === this.formData.ownerPaymentMethodID) {
                  this.convenienceFee = creditCardPercentage
                }
              }

              let feeBankAccount = this.paymentMethods.filter(
                f =>
                  f.label.indexOf('Bank Account') > -1 &&
                  f.value === this.formData.ownerPaymentMethodID
              )
              if (feeBankAccount[0] !== undefined && feeBankAccount[0]) {
                if (feeBankAccount[0].value === this.formData.ownerPaymentMethodID) {
                  this.convenienceFee = achFlatFeeService
                }
              }
            }
          }
        }
      })
  },

  async loadOutstandingBalance() {
    const params = {
      hoaID: this.currentHoaId,
      ownerID: this.currentOwnerId
    }

    await outstandingPayment
      .dispatch('getOverallBalance', {
        params: params
      })
      .then(({ result }) => {
        if (result) {
          this.formData.outstandingBalance = this.formatter.format(result.balanceAmount)
        }
      })
  },

  async loadAccountTypes() {
    await ownerPaymentMethod.dispatch('getBankAccountTypeList').then(t => {
      if (t && t.list && t.list != undefined) {
        console.log('list=' + JSON.stringify(t.list))
        this.accountTypes = t.list
      }
    })
  },

  async getFortisJwtOneTimeToken() {
    await outstandingPayment
      .dispatch('getOneTimeFortisJwtToken', {
        hoaID: this.currentHoaId
      })
      .then(({ result }) => {
        if (result) {
          if (this.isDebug === true)
            console.debug('getOneTimeFortisJwtToken' + JSON.stringify(result))

          if (result) {
            this.requestJwtToken = result
          }
        }
      })
  },

  async loadPaymentMethods() {
    const selectedOwnerUser = this.authUser.ownerUsers.filter(
      f => f.ownerID === this.currentOwnerId
    )

    const params = {
      ownerUserID:
        selectedOwnerUser[0] !== undefined && selectedOwnerUser[0]
          ? selectedOwnerUser[0].ownerUserID
          : this.authUser.ownerUserID || 0
    }

    await outstandingPayment
      .dispatch('getPaymentMethodList', {
        params: params
      })
      .then(({ list }) => {
        if (list) {
          this.paymentMethods = list
        }
      })
  },

  getPayload() {
    if (this.isDebug == true) console.debug('this.formData=' + JSON.stringify(this.formData))

    const payload = {
      ownerID: this.currentOwnerId,
      paymentAmount: this.formData.paymentAmountRaw || 0,
      ownerPaymentMethodID: this.formData.ownerPaymentMethodID || 0,
      postalCode: this.formData.zipCode || ''
    }

    return payload
  },

  async onFormSubmit() {
    if (this.isDebug == true) console.debug('in onFormSubmit....' + JSON.stringify(this.formData))

    if (
      !this.formData ||
      this.formData == undefined ||
      !this.formData.paymentMethodType ||
      this.formData.paymentMethodType == undefined
    ) {
      notifyError('There was a problem processing your payment.')
      return
    }

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    try {
      await this.getFortisJwtOneTimeToken()

      if (this.formData.paymentMethodType == 'stored') {
        const params = this.getPayload()
        let validationMessages = ''

        if (!this.formData.paymentAmountRaw || this.formData.paymentAmountRaw == 0) {
          validationMessages = '<div>Please enter a payment amount.</div>'
        }

        if (!this.formData.ownerPaymentMethodID || this.formData.ownerPaymentMethodID <= 0) {
          validationMessages += '<div>Please select a stored payment method.</div>'
        }

        if (validationMessages != '') {
          notifyError(validationMessages)
          loadingComponent.close()
          return
        }

        this.loading = true

        await outstandingPayment.dispatch('addSendPayment', {
          payload: params,
          done: ({ invalidMessage }) => {
            if (invalidMessage !== undefined && invalidMessage && invalidMessage.length > 0) {
              notifyError(invalidMessage)
              loadingComponent.close()
              return
            } else {
              this.loading = false
              loadingComponent.close()
              this.returnToParent()
            }

            loadingComponent.close()
          }
        })
      } else if (this.formData.paymentMethodType == 'creditcard') {
        //Validate credit card number with luhn
        var isValid = luhn.validate(this.formData.creditCardNumber)

        if (isValid == false) {
          notifyError('Please enter a valid credit card number.')
          loadingComponent.close()
          return
        }

        const lastFourDigits = this.formData.creditCardNumber
          .toString()
          .slice(
            this.formData.creditCardNumber.toString().length - 4,
            this.formData.creditCardNumber.toString().length
          )

        if (
          this.$refs.cardExpirationDateComponent &&
          this.$refs.cardExpirationDateComponent != undefined
        ) {
          this.formData.expirationDate = this.$refs.cardExpirationDateComponent.getValue()

          if (!this.formData.expirationDate || this.formData.expirationDate == undefined) {
            notifyError('The expiration date is required.')
            loadingComponent.close()
            return
          }

          const today = moment()
          if (moment(this.formData.expirationDate) < today) {
            notifyError('The expiration date must be greater than the current date.')
            loadingComponent.close()
            return
          }
        }

        const zipCode = this.formData.zipCode.toString()

        const registerParams = {
          account_number: this.formData.creditCardNumber || '',
          method: 'credit_card'
        }

        await outstandingPayment
          .dispatch('registerOneTimeToken', {
            params: registerParams,
            jwt: this.requestJwtToken,
            amount: this.formData.paymentAmountRaw || 0,
            name: this.formData.cardHolderName || '',
            expMonth: this.formData.expirationDate.month || '',
            expYear: this.formData.expirationDate.year || '',
            cvv: this.formData.cvc || ''
          })
          .then(async ({ result }) => {
            if (result && result.status !== undefined && result.status) {
              notifyError(result.status)
              loadingComponent.close()
              return
            } else if (result) {
              const selectedOwnerUser = this.authUser.ownerUsers.filter(
                f => f.ownerID === this.currentOwnerId
              )

              const params = {
                method: 'card_token',
                ownerUserID:
                  selectedOwnerUser[0] !== undefined && selectedOwnerUser[0]
                    ? selectedOwnerUser[0].ownerUserID
                    : this.authUser.ownerUserID,
                paymentMethodType: 'Credit Card',
                lastFourDigits: lastFourDigits,
                paymentAmount: this.formData.paymentAmountRaw || 0,
                token: result.token_number || '',
                tokenType: result.token_type || '',
                cardHolderName: this.formData.cardHolderName || '',
                expMonth: this.formData.expirationDate.month || '',
                expYear: this.formData.expirationDate.year || '',
                savePaymentMethod: this.formData.addCreditCardPaymentMethod || false,
                accountAlias: this.formData.accountAliasCreditCard || '',
                postalCode: zipCode || ''
              }

              //Register a second one-time token for saving this payment method
              await outstandingPayment
                .dispatch('registerOneTimeToken', {
                  params: registerParams,
                  jwt: this.requestJwtToken,
                  amount: this.formData.paymentAmountRaw || 0,
                  name: this.formData.cardHolderName || '',
                  expMonth: this.formData.expirationDate.month || '',
                  expYear: this.formData.expirationDate.year || '',
                  cvv: this.formData.cvc || ''
                })
                .then(async ({ result }) => {
                  if (result && result.status !== undefined && result.status) {
                    notifyError(result.status)
                    loadingComponent.close()
                    return
                  } else if (result) {
                    params.savePaymentToken = result.token_number

                    await outstandingPayment.dispatch('addOneTimeCreditCardPayment', {
                      payload: params,
                      done: ({ invalidMessage }) => {
                        if (
                          invalidMessage !== undefined &&
                          invalidMessage &&
                          invalidMessage.length > 0
                        ) {
                          notifyError(invalidMessage)
                          loadingComponent.close()
                          return
                        } else {
                          this.loading = false
                          loadingComponent.close()
                          this.returnToParent()
                        }

                        loadingComponent.close()
                      }
                    })
                  }
                })
            }
          })
      } else if (this.formData.paymentMethodType == 'bankaccount') {
        if (this.formData.accountNumber.length < 5 || this.formData.accountNumber.length > 17) {
          notifyError('Please enter five to seventeen numbers for the account number.')
          loadingComponent.close()
          return
        }

        if (isNumeric({ n: this.formData.accountNumber }) == false) {
          notifyError('Please enter only numbers for the account number.')
          loadingComponent.close()
          return
        }

        if (this.formData.routingNumber.length != 9) {
          notifyError('Please enter 9 numbers for the routing number.')
          loadingComponent.close()
          return
        }

        if (isNumeric({ n: this.formData.routingNumber }) == false) {
          notifyError('Please enter only numbers for the routing number.')
          loadingComponent.close()
          return
        }

        const lastFourDigits = this.formData.accountNumber
          .toString()
          .slice(
            this.formData.accountNumber.toString().length - 4,
            this.formData.accountNumber.toString().length
          )

        const registerParams = {
          account_number: this.formData.accountNumber || '',
          routing_number: this.formData.routingNumber || '',
          bank_account_type: this.formData.accountType || '',
          method: 'echeck'
        }

        await outstandingPayment
          .dispatch('registerOneTimeToken', {
            params: registerParams,
            jwt: this.requestJwtToken,
            amount: this.formData.paymentAmountRaw || 0,
            name: this.formData.cardHolderName || ''
          })
          .then(async ({ result }) => {
            if (result && result.status !== undefined && result.status) {
              notifyError(result.status)
              loadingComponent.close()
              return
            } else if (result) {
              const selectedOwnerUser = this.authUser.ownerUsers.filter(
                f => f.ownerID === this.currentOwnerId
              )

              const params = {
                method: 'echeck',
                ownerUserID:
                  selectedOwnerUser[0] !== undefined && selectedOwnerUser[0]
                    ? selectedOwnerUser[0].ownerUserID
                    : this.authUser.ownerUserID,
                paymentMethodType: 'Bank Account',
                lastFourDigits: lastFourDigits,
                token: result.token_number || '',
                tokenType: result.token_type || '',
                paymentAmount: this.formData.paymentAmountRaw || 0,
                accountType: this.formData.accountType || '',
                accountHolderName: this.formData.accountHolderName || '',
                savePaymentMethod: this.formData.addBankPaymentMethod || false,
                accountAlias: this.formData.accountAliasBankAccount || ''
              }
              outstandingPayment
                .dispatch('registerOneTimeToken', {
                  params: registerParams,
                  jwt: this.requestJwtToken,
                  amount: this.formData.paymentAmountRaw || 0,
                  name: this.formData.cardHolderName || ''
                })
                .then(async ({ result }) => {
                  if (result && result.status !== undefined && result.status) {
                    notifyError(result.status)
                    loadingComponent.close()
                    return
                  } else if (result) {
                    params.savePaymentToken = result.token_number

                    await outstandingPayment.dispatch('addOneTimeBankAccountPayment', {
                      payload: params,
                      done: ({ invalidMessage }) => {
                        if (
                          invalidMessage !== undefined &&
                          invalidMessage &&
                          invalidMessage.length > 0
                        ) {
                          notifyError(invalidMessage)
                          loadingComponent.close()
                          return
                        } else {
                          this.loading = false
                          loadingComponent.close()
                          this.returnToParent()
                        }

                        loadingComponent.close()
                      }
                    })
                  }
                })
            }
          })
      }
    } catch (e) {
      // If this is a validation error, get the details for each field
      if (e instanceof ValidationError) {
        this.$refs.form.setErrors(e.fields)
      }

      loadingComponent.close()

      notifyError('There was a problem sending your payment.' + e)
    }
    this.loading = false
    loadingComponent.close()
  }
}
